import { Button, Flex, FlexProps, Text } from '@chakra-ui/react'
import {
  Assessment,
  InsuranceCoverage,
  InsuranceCoverageRequest,
  InsurancePlans,
  isInsuranceCoverageRequest,
  objectToArray,
  PopulatedAssessment,
  WithId,
} from '@hb/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { useMobileLayout } from '../../hooks/useMobileLayout'
import { CoverageRequestView } from './Coverage/CoverageRequestView'
import { CoverageStage } from './Coverage/CoverageStage'
import { NewPlanModal } from './Coverage/NewPlanModal'

const NewPlanButton = ({ onClick }: { onClick: () => void }) => (
  <Flex w="100%" position="relative">
    <Button onClick={onClick} py={1} variant="link" w="100%" size="sm">
      + New Plan
    </Button>
  </Flex>
)

export const AdditionalPlansView = ({
  assessment,
  assessmentId,
  noHeader,
  openPlanId,
  onOpenPlan,
  adminView,
  preview,
  requests,
  plans,
  ...flexProps
}: FlexProps & {
  assessment: Assessment | PopulatedAssessment
  plans: InsurancePlans | undefined
  requests?: InsurancePlans['requests']
  assessmentId: string
  noHeader?: boolean
  preview?: boolean
  onOpenPlan?: (id: string | null) => void
  openPlanId?: string | null
  adminView?: boolean
}) => {
  const { additionalPlans, secondaryCoverage, isMedicaid } = plans || {}
  const nextActions = useMemo(() => {
    if (!adminView) return undefined
    return (assessment as PopulatedAssessment).nextActions || {}
  }, [adminView, assessment])

  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null)
  const planId = useMemo(
    () => (openPlanId === undefined ? selectedPlanId : openPlanId),
    [openPlanId, selectedPlanId],
  )
  const onOpen = useCallback(
    (id: string | null) => {
      const openFunc = onOpenPlan === undefined ? setSelectedPlanId : onOpenPlan
      openFunc(openPlanId === id ? null : id)
    },
    [openPlanId, onOpenPlan],
  )
  const [newPlanOpen, setNewPlanOpen] = useState(false)

  const hasTwoPlans = useMemo(() => {
    return assessment.data?.['insurance-info']?.option === 'two-plans'
  }, [assessment])

  const sortedAdditionalPlans = useMemo<
    WithId<InsuranceCoverage | InsuranceCoverageRequest>[]
  >(() => {
    const requestsArr = objectToArray(requests || {})
    const plansArr = Object.values(additionalPlans || {}).filter(
      p => !secondaryCoverage || p.id !== secondaryCoverage.id,
    )

    return [...requestsArr, ...plansArr]
  }, [additionalPlans, requests, secondaryCoverage])

  const { ref, width } = useMobileLayout(600)

  return (
    <>
      <Flex
        ref={ref}
        w="100%"
        gap={preview ? 1 : 2}
        flexFlow="column"
        px={preview ? 1 : 3}
        py={preview ? 0 : 1}
        {...flexProps}>
        {!adminView && noHeader ? null : (
          <Flex w="100%" align="center">
            {noHeader ? null : (
              <Text
                fontSize={preview ? 'md' : 'lg'}
                lineHeight={1}
                fontWeight={600}
                color="gray.600">
                Potential Plans
              </Text>
            )}
          </Flex>
        )}
        {hasTwoPlans ? (
          <>
            {isMedicaid ? (
              <CoverageStage
                width={width}
                id="primaryCoverage"
                adminView
                assessmentId={assessmentId}
                nextAction={nextActions?.primaryCoverage}
                coverage={plans?.primaryCoverage}
              />
            ) : (
              <CoverageStage
                id="medicaidCoverage"
                adminView
                width={width}
                assessmentId={assessmentId}
                nextAction={nextActions?.medicaidCoverage}
                coverage={plans?.medicaidCoverage}
              />
            )}
          </>
        ) : null}
        {sortedAdditionalPlans.length ? (
          sortedAdditionalPlans.map(plan =>
            isInsuranceCoverageRequest(plan) ? (
              <CoverageRequestView
                assessment={assessment}
                assessmentId={assessmentId}
                adminView={adminView}
                key={plan.id}
                request={plan}
              />
            ) : (
              <CoverageStage
                width={width}
                key={plan.id}
                id={plan.id}
                assessmentId={assessmentId}
                nextAction={nextActions?.additionalPlans?.[plan.id]}
                coverage={plan}
                isOpen={planId === plan.id}
                onOpenToggle={() => onOpen(planId === plan.id ? null : plan.id)}
                adminView={adminView}
              />
            ),
          )
        ) : (
          <Text
            px={3}
            py={1}
            bg="gray.50"
            fontStyle="italic"
            border="1px solid #cdcdcd"
            borderRadius={6}>
            No potential plans
          </Text>
        )}
        {!adminView ? null : <NewPlanButton onClick={() => setNewPlanOpen(true)} />}
      </Flex>
      {adminView && newPlanOpen ? <NewPlanModal onClose={() => setNewPlanOpen(false)} /> : null}
    </>
  )
}
