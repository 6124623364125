import { nameField } from '@hb/shared'
import React from 'react'
import { useHistory } from 'react-router'
import { useUpdateDoc } from '../../hooks/backend/useUpdateDoc'
import { useAppModals, useAuth } from '../../store'
import { GenericEditModal } from '../DataView/GenericEditModal'

export const NameEntryModal = ({ id = 'root' }: { id?: string }) => {
  const { openId, close } = useAppModals(s => s.nameEntry)
  const history = useHistory()
  const { ref, user } = useAuth()
  const { fname, lname, nickname, middleName, pronouns } = user || {}
  const update = useUpdateDoc('name')
  return openId === id ? (
    <GenericEditModal
      isOpen
      onClose={close}
      onSubmit={data =>
        update(ref, '', {
          fname: (data.fname || '').trim(),
          lname: (data.lname || '').trim(),
          middleName: (data.middleName || '').trim(),
          nickname: (data.nickname || '').trim(),
          pronouns: (data.pronouns || '').trim(),
        }).then(res => {
          history.push('/assessment')
          return res
        })
      }
      data={{ fname, lname, nickname, middleName, pronouns }}
      field={nameField}
    />
  ) : null
}
