import { CalendarIcon, DownloadIcon, EditIcon, RepeatIcon } from '@chakra-ui/icons'
import {
  Badge,
  BadgeProps,
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  adminRoles,
  backupDeliveryPlanField,
  colors,
  currentPregnancyField,
  deliveryPlanField,
  FieldMap,
  FieldTypes,
  formatPhoneNumber,
  getDateTimeRangeString,
  getFullName,
  localizeDateRange,
  PracticeVisit,
  PracticeVisitDraft,
  PreviousVersion,
  riskAssessmentField,
  SubmittedAuthentication,
  SubmittedVisitData,
  visitHistoryField,
  visitTypeNames,
  WithId,
} from '@hb/shared'
import faxIcon from '@hb/shared-frontend/icons/fax.svg'
import { FORM_ERROR } from 'final-form'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import {
  addPracticeVisit,
  downloadFromStorage,
  faxVisitPdf,
  requestVisitPdf,
} from '../../../../backend'
import { useCachedUser } from '../../../../collections/hooks/cached'
import { useApp } from '../../../../contexts/AppContext'
import { PopUpMessageContext } from '../../../../contexts/PopUpMessage/PopUpMessageContext'
import { usePracticeAccess } from '../../../../contexts/PracticeAccess/PracticeAccessProvider'
import { useAuth } from '../../../../store'
import { formatValue } from '../../../../utils'
import { ActionLog } from '../../../ActionLog'
import { ActionConfirm } from '../../../Alerts'
import { ModalCloseButton } from '../../../Buttons/ModalCloseButton'
import { DataCell } from '../../../DataView'
import { SimpleForm } from '../../../forms'
import { DataLabel } from '../../../forms/Input/DataLabel'
import { AuthenticateAndSubmit } from './AuthenticateCheckbox'
import { AuthenticationView } from './AuthenticationView'
import { newVisitField } from './fields'

const DataBadge = (props: BadgeProps) => (
  <Badge
    bg={colors.green.hex}
    textShadow="1px 1px 3px #00000077"
    color="white"
    boxShadow="1px 1px 4px #00000055"
    {...props}
  />
)

const CurrentPregnancyView = ({ value }: { value: PracticeVisit['currentPregnancy'] }) => {
  const formattedValue = useMemo(
    () =>
      formatValue({
        field: currentPregnancyField,
        value,
      }),
    [value],
  )

  return (
    <Flex gap={2} align="center">
      <DataLabel>Current Pregnancy</DataLabel>
      <DataCell>{formattedValue}</DataCell>
    </Flex>
  )
}

export const ParaView = ({ para }: { para: PracticeVisit['para'] }) => (
  <Flex gap={2} flexFlow="row wrap" align="center" py={1}>
    <DataLabel>Para:</DataLabel>
    {para.map((p, i) => (
      <DataBadge fontSize="sm" key={i}>
        {p}
      </DataBadge>
    ))}
  </Flex>
)

const HistoryView = ({
  history,
  historyDetails,
}: {
  history: PracticeVisit['history']
  historyDetails: string | undefined
}) => {
  const options = useMemo(
    () => history.map(v => visitHistoryField.options.find(o => o.id === v)),
    [history],
  )

  return (
    <>
      <Flex w="100%" gap={2}>
        <DataLabel>History</DataLabel>
        <Flex flex={1} gap={2} minW="0" flexFlow="row wrap">
          {options.map((o, i) => (
            <DataBadge key={i}>{o?.text}</DataBadge>
          ))}
        </Flex>
      </Flex>
      {historyDetails ? (
        <Flex flexFlow="column" mt={2} gap={0}>
          <DataLabel>History Details</DataLabel>
          <DataCell py={0}>{historyDetails}</DataCell>
        </Flex>
      ) : null}
    </>
  )
}

const DeliveryPlanView = ({
  deliveryPlan,
  deliveryPlanDetails,
  backupDeliveryPlan,
}: {
  deliveryPlan: PracticeVisit['deliveryPlan']
  deliveryPlanDetails: string | undefined
  backupDeliveryPlan?: PracticeVisit['backupDeliveryPlan']
}) => {
  const formattedDeliveryPlan = useMemo(
    () =>
      formatValue({
        field: deliveryPlanField,
        value: deliveryPlan,
      }),
    [deliveryPlan],
  )

  return (
    <>
      <Flex flexFlow="column">
        <DataLabel>Delivery Plan</DataLabel>
        <DataCell p={0}>{formattedDeliveryPlan}</DataCell>
      </Flex>
      <BackupDeliveryPlanView backupDeliveryPlan={backupDeliveryPlan} />
      {deliveryPlanDetails ? (
        <Flex gap={2} align="center">
          <DataLabel>Delivery - Additional Details</DataLabel>
          <DataCell py={0}>{deliveryPlanDetails}</DataCell>
        </Flex>
      ) : null}
    </>
  )
}

const HospitalView = ({ hospital }: { hospital: string }) => (
  <Flex gap={2} align="center">
    <DataLabel>Hospital</DataLabel>
    <DataCell py={0}>{hospital}</DataCell>
  </Flex>
)

const KeywordsView = ({ keywords }: { keywords: string | undefined }) => (
  <Flex gap={2} align="center">
    <DataLabel>Keywords</DataLabel>
    {keywords ? (
      keywords.split(',').map((k, i) => <DataBadge key={i}>{k}</DataBadge>)
    ) : (
      <DataCell py={0}>None</DataCell>
    )}
  </Flex>
)

const RiskAssessmentView = ({
  riskAssessment,
}: {
  riskAssessment: PracticeVisit['riskAssessment']
}) => {
  const formattedRiskAssessment = useMemo(
    () =>
      formatValue({
        field: riskAssessmentField,
        value: riskAssessment,
      }),
    [riskAssessment],
  )

  return (
    <Flex gap={2} align="center">
      <DataLabel>Risk Assessment</DataLabel>
      <DataCell py={0}>{formattedRiskAssessment}</DataCell>
    </Flex>
  )
}

const GravidaView = ({ gravida }: { gravida: number }) => (
  <Flex gap={2} align="center" py={1}>
    <DataLabel>Gravida:</DataLabel>
    <DataBadge fontSize="sm">{gravida}</DataBadge>
  </Flex>
)

const BackupDeliveryPlanView = ({
  backupDeliveryPlan,
}: {
  backupDeliveryPlan: PracticeVisit['backupDeliveryPlan']
}) => {
  const formatted = useMemo(
    () =>
      backupDeliveryPlan
        ? formatValue({
            field: backupDeliveryPlanField,
            value: backupDeliveryPlan,
          })
        : 'None',
    [backupDeliveryPlan],
  )
  return (
    <Flex
      align={backupDeliveryPlan ? 'flex-start' : 'center'}
      flexFlow={backupDeliveryPlan ? 'column' : 'row'}
      gap={backupDeliveryPlan ? 0 : 2}>
      <DataLabel>Delivery plan in case of need for transfer</DataLabel>
      <DataCell opacity={backupDeliveryPlan ? 1 : 0.6} py={0}>
        {formatted}
      </DataCell>
    </Flex>
  )
}

const AllergyNotesView = ({ allergyNotes }: { allergyNotes: string | undefined }) => (
  <Flex flexFlow="column" gap={0}>
    <DataLabel>Allergy Notes</DataLabel>
    <DataCell py={0}>{allergyNotes || ''}</DataCell>
  </Flex>
)
const NotesView = ({ notes }: { notes: string | undefined }) => (
  <Flex flexFlow="column" gap={0}>
    <DataLabel>Notes</DataLabel>
    <DataCell py={0}>{notes || ''}</DataCell>
  </Flex>
)

type SendFaxData = {
  faxToNumber: string
  notes: string
}
const sendFaxField: FieldMap = {
  name: 'Send fax',
  children: {
    faxToNumber: {
      type: FieldTypes.PHONE,
      placeholder: 'Fax to #',
    },
    notes: {
      type: FieldTypes.TEXTAREA,
      placeholder: 'Notes',
    },
  },
}

const BottomButton = ({
  onClick,
  text,
  bg = 'green.400',
  hoverBg = 'green.600',
}: {
  onClick: () => void
  text: string
  bg?: string
  hoverBg?: string
}) => (
  <Button
    minH="0"
    h={8}
    onClick={e => {
      e.stopPropagation()
      onClick()
    }}
    borderTopRadius={0}
    variant="unstyled"
    w="100%"
    bg={bg}
    _hover={{ bg: hoverBg }}
    py={1}>
    <Text textShadow="1px 1px 3px #00000088" fontSize="sm" color="white">
      {text}
    </Text>
  </Button>
)

const SentFaxesView = ({
  visit,
  onSendClick,
}: {
  visit: WithId<PracticeVisit>
  onSendClick: () => void
}) => {
  const { sentFaxes = [] } = visit

  return (
    <VStack spacing={0} divider={<Divider />} w="100%" flexFlow="column">
      <Text px={2} py={1} fontSize="lg" fontFamily="Open Sans" color="gray.600">
        Sent Faxes
      </Text>
      {sentFaxes.map((fax, i) => (
        <Flex bg="gray.50" pt={2} px={3} pb={3} w="100%" flexFlow="column" key={i} gap={1}>
          <Flex align="center" gap={1}>
            <Text fontSize="sm" fontWeight={600} opacity={0.7}>
              Sent to
            </Text>
            <Text fontSize="sm">{formatPhoneNumber(fax.sentToNumber)}</Text>
          </Flex>
          <Box bg="white" border="1px solid #cdcdcd" borderRadius={3} py={1} px={2} w="100%">
            <Text lineHeight={1} fontSize="sm" color="gray.600">
              Notes
            </Text>
            <Text>{fax.notes}</Text>
          </Box>
          <ActionLog on={fax.sentOn} by={fax.sentBy} action="Sent" />
        </Flex>
      ))}
      <BottomButton onClick={onSendClick} text="Send another fax" />
    </VStack>
  )
}

const SendVisitFaxPopover = ({
  practiceId,
  visit,
}: {
  practiceId: string
  visit: WithId<PracticeVisit>
}) => {
  const { id: visitId, sentFaxes = [] } = visit
  const { showMessage } = useContext(PopUpMessageContext)
  const [isOpen, setIsOpen] = useState(false)
  const [faxing, setFaxing] = useState(false)
  const [viewing, setViewing] = useState<'view' | 'send'>(sentFaxes.length ? 'view' : 'send')
  const { appName } = useApp()

  const handleClose = useCallback(() => {
    setIsOpen(false)
    setViewing(sentFaxes.length ? 'view' : 'send')
  }, [sentFaxes])
  const onFax = useCallback(
    async ({ faxToNumber, notes }: SendFaxData) => {
      setFaxing(true)
      try {
        // Call fax function
        await faxVisitPdf({ appName, faxToNumber, practiceId, visitId, notes })
        showMessage({
          text: 'Fax sent',
          type: 'success',
        })
        setViewing('view')
        setIsOpen(false)
      } catch (err: any) {
        showMessage({
          text: 'Failed to fax',
          subText: err?.message || 'An error occurred',
          type: 'error',
        })
        setFaxing(false)
        return { [FORM_ERROR]: err?.message || 'Failed to fax' }
      }
      setFaxing(false)
      return undefined
    },
    [showMessage, appName, practiceId, visitId],
  )
  return (
    <Popover
      isOpen={faxing || isOpen}
      onClose={handleClose}
      onOpen={() => setIsOpen(true)}
      isLazy
      closeOnBlur={false}
      placement="top-end">
      <PopoverTrigger>
        <Box>
          <Tooltip bg="gray.50" color="gray.600" placement="top" hasArrow label="Fax Visit PDF">
            <IconButton
              isLoading={faxing}
              color="gray.600"
              aria-label="Fax"
              size="sm"
              icon={<Image src={faxIcon} w="24px" opacity={0.9} />}
              borderRadius="full"
              bg="gray.50"
              boxShadow="1px 1px 4px #00000066"
            />
          </Tooltip>
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverBody p={0}>
            {!!sentFaxes.length && viewing === 'view' ? (
              <SentFaxesView onSendClick={() => setViewing('send')} visit={visit} />
            ) : (
              <Box pt={1} w="100%">
                <SimpleForm submitText="Send Fax" field={sendFaxField} onSubmit={onFax} />
                {sentFaxes.length ? (
                  <BottomButton
                    bg="gray.500"
                    hoverBg="gray.400"
                    onClick={() => setViewing('view')}
                    text="View sent faxes"
                  />
                ) : null}
              </Box>
            )}
          </PopoverBody>
          <PopoverCloseButton />
          <PopoverArrow />
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

const VisitViewBody = ({
  visit,
  isPreviousVersion,
  practiceId,
  onEditClick,
  inTab,
}: {
  visit: WithId<PracticeVisit> | PreviousVersion<PracticeVisit>
  isPreviousVersion?: boolean
  practiceId: string
  onEditClick?: () => void
  inTab?: boolean
}) => {
  const {
    startTime,
    endTime,
    type,
    currentPregnancy,
    patientFirst,
    patientLast,
    history,
    deliveryPlan,
    backupDeliveryPlan,
    deliveryPlanDetails,
    hospital,
    historyDetails,
    riskAssessment,
    signedPdfStoragePath,
  } = visit

  const visitTypeName = useMemo(() => visitTypeNames[type], [type])
  const { processResponse } = useContext(PopUpMessageContext)
  const [downloading, setDownloading] = useState(false)
  const onDownload = useCallback(
    async (forceRegenerate?: boolean) => {
      const asCurrentVersion = visit as WithId<PracticeVisit>
      if (!asCurrentVersion.id || isPreviousVersion) {
        processResponse({
          error: 'Unable to download PDF for previous versions',
        })
        return
      }
      setDownloading(true)
      try {
        if (signedPdfStoragePath && !forceRegenerate)
          await downloadFromStorage(signedPdfStoragePath)
        else {
          const {
            data: { storagePath },
          } = await requestVisitPdf({
            practiceId,
            visitId: asCurrentVersion.id,
            forceRegenerate: !!forceRegenerate,
          })
          await downloadFromStorage(storagePath)
        }
      } catch (err: any) {
        processResponse({ error: err?.message || 'Failed to download PDF' })
        console.error(err)
      }
      setDownloading(false)
    },
    [signedPdfStoragePath, processResponse, visit, practiceId, isPreviousVersion],
  )

  const visitTimeLabel = useMemo(() => {
    const now = Date.now()
    if (startTime > now) return 'Future Visit'
    if (endTime < now) return 'Past Visit'
    return 'Current Visit'
  }, [startTime, endTime])

  return (
    <Flex px={2} flexFlow="column">
      <Flex minH={8} pr={inTab ? 0 : 8} w="100%" align="center">
        <Text fontSize="lg" fontFamily="Open Sans" color="gray.600">
          {visitTypeName} with {patientFirst} {patientLast}
        </Text>
        <Badge ml={2} colorScheme="green">
          {visitTimeLabel}
        </Badge>
        {onEditClick ? (
          <Button
            size="sm"
            borderRadius="full"
            variant="ghost"
            color={colors.green.hex}
            ml="auto"
            aria-label="Edit and Reauthenticate"
            onClick={onEditClick}>
            <EditIcon />
            <Text ml={1}>Edit and Reauth</Text>
          </Button>
        ) : null}
      </Flex>
      <Flex gap={2} align="center" py={1}>
        <CalendarIcon color="gray.500" />
        <Text position="relative" top="1px" lineHeight={1} fontSize="sm" fontFamily="Hero-New">
          {getDateTimeRangeString(startTime, endTime)}
        </Text>
      </Flex>
      <GravidaView gravida={visit.gravida} />
      <ParaView para={visit.para} />
      <CurrentPregnancyView value={currentPregnancy} />
      <Divider mb={2} />
      <HistoryView history={history} historyDetails={historyDetails} />
      <Divider my={2} />
      <DeliveryPlanView
        backupDeliveryPlan={backupDeliveryPlan}
        deliveryPlan={deliveryPlan}
        deliveryPlanDetails={deliveryPlanDetails}
      />
      <Divider my={1} />
      <HospitalView hospital={hospital} />
      <RiskAssessmentView riskAssessment={riskAssessment} />
      <Divider my={1} />
      <AllergyNotesView allergyNotes={visit.allergyNotes} />
      <Divider my={1} />
      <NotesView notes={visit.notes} />
      <Divider my={1} />
      <KeywordsView keywords={visit.keywords} />
      <Divider my={2} />
      <Flex align="center" w="100%" gap={2}>
        <Flex minW="0" flex={1}>
          <AuthenticationView authentication={visit.authentication} />
        </Flex>
        {isPreviousVersion ? null : (
          <Flex gap={2} align="center">
            <Tooltip bg="gray.50" color="gray.600" placement="top" hasArrow label="Download PDF">
              <IconButton
                onClick={() => onDownload()}
                isLoading={downloading}
                color="gray.600"
                aria-label="Download"
                icon={<DownloadIcon />}
                borderRadius="full"
                bg="white"
                boxShadow="1px 1px 4px #00000066"
              />
            </Tooltip>
            {isPreviousVersion ? null : (
              <SendVisitFaxPopover practiceId={practiceId} visit={visit as WithId<PracticeVisit>} />
            )}
            {visit.signedPdfStoragePath ? (
              <Tooltip
                bg="gray.50"
                color="gray.600"
                placement="top"
                hasArrow
                label="Regenerate PDF">
                <IconButton
                  onClick={() => onDownload(true)}
                  isLoading={downloading}
                  color="gray.600"
                  aria-label="Regenerate"
                  icon={<RepeatIcon />}
                  borderRadius="full"
                  size="sm"
                  bg="gray.50"
                  boxShadow="1px 1px 4px #00000066"
                />
              </Tooltip>
            ) : null}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

const EditAndReauthenticateVisit = ({
  visit,
  practiceId,
  onClose,
}: {
  visit: WithId<PracticeVisit>
  onClose: () => void
  practiceId: string
}) => {
  const { appName } = useApp()
  // visit startTime(number) and endTime(number) should be replaced with startTime(string), endTime(string), and date(string)
  const [hasGoneBack, setHasGoneBack] = useState(false)
  const [formData, setFormData] = useState<PracticeVisitDraft>(() => {
    const { startTime, endTime, ...rest } = visit

    const localized = localizeDateRange(startTime, endTime)
    return {
      ...rest,
      ...localized,
    }
  })
  const [savedData, setSavedData] = useState<PracticeVisitDraft | null>(null)
  const [authChecked, setAuthChecked] = useState(false)
  const { assessmentId, patientId } = visit
  const onSubmitForm = useCallback(
    async (data: PracticeVisitDraft) => {
      setSavedData({ ...data, assessmentId, patientId })
      return undefined
    },
    [assessmentId, patientId],
  )
  const { showMessage } = useContext(PopUpMessageContext)
  const [confirmDiscard, setConfirmDiscard] = useState(false)

  const onSubmit = useCallback(
    async (authentication: SubmittedAuthentication): Promise<void> => {
      if (!savedData) return
      try {
        await addPracticeVisit({
          appName,
          practiceId,
          authentication,
          visitId: visit.id,
          ...savedData,
        })
        onClose()
      } catch (err: any) {
        showMessage({
          text: 'Failed to save visit',
          subText: err?.message || 'An error occurred',
          type: 'error',
        })
      }
    },
    [appName, practiceId, savedData, visit, showMessage, onClose],
  )

  if (savedData) {
    return (
      <Box w="100%" p={3}>
        <VisitDraftView
          onEditClick={() => {
            setFormData(savedData)
            setSavedData(null)
            setHasGoneBack(true)
          }}
          draft={savedData}
        />
        <AuthenticateAndSubmit
          authChecked={authChecked}
          isReauth
          itemName="visit"
          onChange={setAuthChecked}
          onSubmit={onSubmit}
          practiceId={practiceId}
        />
        <ActionConfirm
          colorScheme="red"
          header="Discard edited visit?"
          body={null}
          confirmText="Discard and close"
          isOpen={confirmDiscard}
          onClose={() => setConfirmDiscard(false)}
          onConfirm={onClose}
        />
        <ModalCloseButton onClick={() => setConfirmDiscard(true)} />
      </Box>
    )
  }
  return (
    <Box w="100%">
      <SimpleForm<SubmittedVisitData>
        value={formData}
        field={newVisitField}
        canSubmitClean={hasGoneBack}
        onCancel={() => onClose()}
        onSubmit={onSubmitForm}
      />
      <ModalCloseButton onClick={() => onClose()} />
    </Box>
  )
}

export const VisitView = ({
  visit,
  practiceId,
  onClose,
}: {
  visit: WithId<PracticeVisit>
  practiceId: string
  onClose?: () => void
}) => {
  const isAdmin = useAuth(s => s.admin)
  const { practiceAccess } = usePracticeAccess()
  const [isEditing, setIsEditing] = useState(false)
  const canEdit = useMemo(() => {
    if (isAdmin) return true
    const role = practiceAccess?.[practiceId]
    return !!role && adminRoles.includes(role)
  }, [isAdmin, practiceAccess, practiceId])

  const numPreviousVersions = useMemo(
    () => visit.previousVersions?.length || 0,
    [visit.previousVersions],
  )

  if (isEditing) {
    return (
      <EditAndReauthenticateVisit
        onClose={() => setIsEditing(false)}
        visit={visit}
        practiceId={practiceId}
      />
    )
  }

  if (numPreviousVersions) {
    return (
      <Tabs colorScheme="green" w="100%">
        <TabList bg="white">
          <Tab py={3} key={0}>
            <Text>Current Version</Text>
          </Tab>
          {visit.previousVersions.map((v, i) => (
            <Tab key={i + 1}>
              <Text>Version {numPreviousVersions - i}</Text>
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel key={0}>
            <VisitViewBody
              inTab
              onEditClick={canEdit ? () => setIsEditing(true) : undefined}
              visit={visit}
              practiceId={practiceId}
            />
          </TabPanel>
          {visit.previousVersions.map((v, i) => (
            <TabPanel key={i + 1}>
              <VisitViewBody inTab isPreviousVersion key={i} practiceId={practiceId} visit={v} />
            </TabPanel>
          ))}
        </TabPanels>
        {onClose ? <ModalCloseButton onClick={() => onClose()} /> : null}
      </Tabs>
    )
  }

  return (
    <Box p={3} w="100%">
      <VisitViewBody
        practiceId={practiceId}
        onEditClick={canEdit ? () => setIsEditing(true) : undefined}
        visit={visit}
      />
      {onClose ? <ModalCloseButton onClick={() => onClose()} /> : null}
    </Box>
  )
}

export const VisitDraftView = ({
  draft,
  onEditClick,
}: {
  draft: PracticeVisitDraft | SubmittedVisitData
  onEditClick?: () => void
}) => {
  const {
    currentPregnancy,
    date,
    startTime,
    endTime,
    deliveryPlan,
    deliveryPlanDetails,
    backupDeliveryPlan,
    gravida,
    history,
    historyDetails,
    hospital,
    patientId,
    para,
    riskAssessment,
  } = draft

  const { data: user } = useCachedUser(patientId)

  const fullName = useMemo(() => getFullName(user), [user])

  const visitTimeLabel = useMemo(() => {
    const visitStart = new Date(`${date}T${startTime}`).getTime()
    const visitEnd = new Date(`${date}T${endTime}`).getTime()

    const now = Date.now()
    if (visitStart > now) return 'Future Visit'
    if (visitEnd < now) return 'Past Visit'
    return 'Current Visit'
  }, [startTime, endTime, date])

  return (
    <Flex w="100%" px={2} flexFlow="column">
      <Flex pr={8} w="100%" align="center">
        <Text fontSize="lg" fontFamily="Open Sans" color="gray.600">
          Visit Draft for {fullName}
        </Text>
        <Badge ml={2} colorScheme="green">
          {visitTimeLabel}
        </Badge>
        {onEditClick ? (
          <Button onClick={onEditClick} ml="auto" size="sm" colorScheme="green" variant="ghost">
            <EditIcon />
            <Text ml={1}>Edit Draft</Text>
          </Button>
        ) : null}
      </Flex>
      <Flex gap={2} align="center" py={1}>
        <CalendarIcon color="gray.500" />
        <Text position="relative" top="1px" lineHeight={1} fontSize="sm" fontFamily="Hero-New">
          {getDateTimeRangeString(new Date(`${date}T${startTime}`), new Date(`${date}T${endTime}`))}
        </Text>
      </Flex>
      <Flex gap={2} align="center" py={1}>
        <DataLabel>Gravida:</DataLabel>
        <DataBadge fontSize="sm">{gravida}</DataBadge>
      </Flex>
      <ParaView para={para} />
      <CurrentPregnancyView value={currentPregnancy} />
      <Divider mb={2} />
      <HistoryView history={history} historyDetails={historyDetails} />
      <Divider my={2} />
      <DeliveryPlanView
        backupDeliveryPlan={backupDeliveryPlan}
        deliveryPlan={deliveryPlan}
        deliveryPlanDetails={deliveryPlanDetails}
      />
      <Divider my={1} />
      <HospitalView hospital={hospital} />
      <RiskAssessmentView riskAssessment={riskAssessment} />
      <Divider my={1} />
      <AllergyNotesView allergyNotes={draft.allergyNotes} />
      <Divider my={1} />
      <NotesView notes={draft.notes} />
      <Divider my={1} />
      <KeywordsView keywords={draft.keywords} />
    </Flex>
  )
}
